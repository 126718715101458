@import 'src/styles/variables/colors';

.link {
  cursor: pointer;
  border: 0;
  outline: none;
  padding: 0;
  background: none;
  color: $ms-midnight;
  font-weight: bold;
  font-size: 1rem;
  text-decoration: underline;

  &:active,
  &:focus {
    box-shadow: 0 0 1px 2px;
  }
}
