@import 'src/styles/variables/colors';
@import 'src/styles/mixins/lineHeight';
@import 'src/styles/variables/spacings';

.title {
  color: $ms-tree;
}

.list {
  @include large-spacing(bottom);
  @include medium-spacing(top);
  padding-left: 1em;
}

.listItem {
  @include lineHeight(paragraph);
  @include small-spacing(bottom);
  color: $ms-tree;
}

.listItemContent {
  color: black;
}
