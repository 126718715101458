@import 'src/styles/fontWeights';
@import 'src/styles/mixins/fontSize';
@import 'src/styles/variables/keyframes';
@import 'src/styles/variables/colors';
@import 'src/styles/variables/spacings';

$errorFieldHeight: $large-single-margin + 2px;

.container {
  @include fontSize(body, 0.85);
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 100;
  min-height: $errorFieldHeight;
  margin-top: -$errorFieldHeight;
  margin-bottom: 0;
  transform: translateY($errorFieldHeight);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.noInteraction {
  pointer-events: none;
}

.error {
  @include tiny-spacing(bottom);
  background: $form-border-error;
  min-height: $large-single-margin;
  line-height: $large-single-margin - 1;
  border-radius: 0 0 6px 6px;
  color: $ms-white;
  display: inline-block;
  font-size: 0.9rem;
  margin-bottom: 0;
  padding: 1px 0.5rem;
  text-align: right;
  position: relative;
  letter-spacing: 0.01em;
  max-width: calc(100% - 8px);
  animation: showFromTop 0.15s ease both;
}

.error--withspacing {
  max-width: 60%;
}

.container-checkbox {
  max-width: 425px;
  position: static;

  .error {
    margin-left: -4px;
  }
}

.visiblyHidden {
  display: block;
  position: absolute;
  left: -9999px;
  top: -9999px;
}
