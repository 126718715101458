@import 'src/styles/variables/spacings';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/colors';
@import 'src/styles/fontWeights';

.formField {
  @include single-spacing(bottom);
  position: relative;

  &.withRadio {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &.withSiblingsMobile {
    @include small-spacing(left, margin);
    @include small-spacing(right, margin);
    flex: 1;
    width: 100%;

    &:first-child {
      @include no-spacing(left, margin);
    }

    &:last-child {
      @include no-spacing(right, margin);
    }
  }

  &.withSiblings {
    flex: 1;

    @media (min-width: $breakpoint-medium) {
      @include small-spacing(left, margin);
      @include small-spacing(right, margin);
      width: 100%;

      &:first-child {
        @include no-spacing(left, margin);
      }

      &:last-child {
        @include no-spacing(right, margin);
      }
    }
  }

  &.desktopSpacer50 {
    @media (min-width: $breakpoint-medium) {
      &,
      &:first-child {
        margin-left: calc(50% + 8px);
      }
    }
  }
}

.formFieldError {
  input[type='tel'],
  select {
    background-color: $form-background-error;
    border: 2px solid $form-border-error;
    border-radius: 6px 6px 0 6px;

    &:focus,
    &.focus {
      background-color: $ms-white;
    }
  }
}

.inputAction {
  @include small-spacing();
  text-align: center;

  a {
    color: black;
    font-weight: $fontWeightBold;

    &:hover {
      text-decoration: none;
    }
  }
}

.inputActionError {
  color: $form-border-error;
  font-weight: $fontWeightBold;
}

.inputNotice {
  color: $ms-noon;
}

.isDisabled {
  opacity: 0.2;
}

.checkboxWrap {
  display: flex;
  flex-direction: row-reverse;

  label {
    margin-left: 1rem;
    font-weight: 400;
  }

  @media screen and (max-width: $breakpoint-medium) {
    justify-content: flex-end;
  }
}
