@import 'src/styles/variables/spacings';
@import 'src/styles/variables/colors';
@import 'src/styles/fontWeights';
@import 'src/styles/mixins/lineHeight';

.wrapper {
  @media screen and (min-width: $breakpoint-medium) {
    min-height: 41px;
  }
}

.container {
  @include lineHeight(paragraph);
  @include medium-small-spacing(bottom, padding);
  @include medium-small-spacing(top, padding);
  @include page-spacing(padding);

  background: $ms-brand-85;
  font-size: 1.0625rem;
  text-align: center;

  @media screen and (min-width: $breakpoint-medium) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

.center {
  justify-content: center;

  a {
    margin-left: 1rem;
  }
}

.bundleNotAvailable {
  background: $ms-red;
  color: $ms-white;
  font-weight: $fontWeightMedium;
}

.centerText {
  max-width: 900px;
  margin: 0 auto;
}

.colorHoney {
  background: $ms-honey-85;
}
