@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/spacings';

.main {
  padding-bottom: 60px;
}

.section {
  @include page-spacing(padding);
  @include large-spacing(bottom, padding);
  max-width: 380px;
  margin: 0 auto;

  &.pro {
    padding-top: $large-margin;
  }

  @media screen and (min-width: $breakpoint-medium) {
    display: flex;
    max-width: 910px;
    margin: 0 auto;
    align-items: center;

    .visual {
      width: 55%;
    }
  }
}

.visual {
  display: block;
}

.gray {
  background: $form-background;
}

.second {
  order: 2;
}

.first {
  order: 1;
}

.title {
  @include page-spacing(padding);
  @include large-spacing(bottom, padding);
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
  max-width: 380px;
  margin: 0 auto;

  @media screen and (min-width: $breakpoint-medium) {
    max-width: 910px;
    margin: 0 auto;
    align-items: center;
    padding-bottom: 40px;
  }
}

.text {
  @media screen and (min-width: $breakpoint-medium) {
    @include single-spacing(top);
    @include double-spacing(bottom);
  }
}

.image {
  width: 620px;
  margin: 0 auto;
  max-width: 100%;
  display: block;
}

.form {
  width: 100%;
  max-width: 312px;
  @include single-spacing(top, margin);

  button {
    width: 100%;
  }
}

.homePageButton {
  @include single-spacing(top);
  width: 100%;

  @media screen and (min-width: $breakpoint-medium) {
    max-width: 305px;
  }
}

.dvgHeader {
  display: none;
}
