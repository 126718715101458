@import 'src/styles/variables/spacings';
@import 'src/styles/mixins/lineHeight';

.list {
  @include single-spacing(bottom);
  @include single-spacing(top);
  list-style: none;
  padding-left: 0;
}

.item {
  @include lineHeight(paragraph);
  @include single-spacing(bottom);
  display: flex;
}

.icon {
  @include large-single-spacing(right);
}
