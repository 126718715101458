@import 'src/styles/variables/colors';
@import 'src/styles/mixins/lineHeight';

.inlineWarning {
  margin: -2px 0 0.6rem;
  padding: 0.8rem 0.8rem;
  background: #f8f8f8;
  @include lineHeight(paragraph);
  background: inherit;
  color: $ms-purple;
  border: 2px solid $ms-purple;
  border-radius: 0 0 6px 6px;
}
