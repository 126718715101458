@import 'src/styles/variables/spacings';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/colors';
@import 'src/styles/fontWeights';
@import 'src/styles/mixins/fontSize';

.columnForm {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint-medium) {
    flex-direction: row;
  }
}

.successMessage {
  @include single-spacing(bottom);
  border: 1px solid $ms-tree;
  border-radius: 6px;
  background: $ms-green-40;
  padding: 0.5rem;
}

.actions {
  @include single-spacing(top);
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: $breakpoint-medium) {
    justify-items: center;
  }
}

.actions-row {
  flex-direction: row;
}

.row {
  display: block;

  &.hasFieldsMobile {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &.hasFields {
    @media (min-width: $breakpoint-medium) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &.hasCenterRadio {
    display: flex;
    justify-content: center;
  }

  &.isColumn {
    width: 100%;

    @media screen and (min-width: $breakpoint-medium) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 0.8rem;
      margin-right: 0.8rem;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.spaceBelow {
    @include single-spacing(bottom);
  }

  &.spaceBelowLarge {
    @include double-spacing(bottom);
  }
}

.button {
  line-height: 44px;
  width: 100%;

  &.singleCta {
    @media screen and (min-width: $breakpoint-medium) {
      justify-items: center;
      max-width: 311px;
    }
  }

  &:nth-child(1) {
    margin-right: 0.5rem;
  }

  &:nth-child(2) {
    margin-left: 0.5rem;
  }

  &:last-child {
    margin-right: 0;
  }
}

.actions-column {
  flex-direction: column;
  margin-top: 2.5rem;

  .button {
    @include single-spacing(bottom);
    margin-left: 0;
    margin-right: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.actions-column-reverse {
  flex-direction: column-reverse;

  .button {
    margin: 0 0 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .backButton {
    margin-top: $single-margin;
  }
}

.actions-responsive {
  flex-direction: column-reverse;

  @media screen and (min-width: $breakpoint-medium) {
    flex-direction: row;
  }

  .button {
    margin: 0 0 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .backButton {
    margin-top: $single-margin;
  }

  @media screen and (min-width: $breakpoint-medium) {
    .button {
      line-height: 44px;
      width: 100%;
      margin: 0 0.5rem;

      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }

    .backButton {
      margin: 0;
    }
  }
}

.ctaButton {
  @include fontSize(body, 1.1875);
  font-weight: $fontWeightBold;
}

.backButton {
  @include fontSize(body, 1.1875);
  background-color: transparent;
  border: 1px solid $ms-midnight;
  color: $ms-midnight;
  line-height: 42px;
  width: 100%;

  &.fullButton {
    width: 100%;
  }

  &:hover {
    background-color: $ms-daytime;
  }

  &:before {
    border: 0;
  }
}

.sectionBegin {
  @include single-spacing(bottom);
  background: rgba(255, 255, 255, 0.5);
  padding: 1.1rem 1rem 0;
  border-radius: 10px;
  margin-top: 1.2rem;

  &.visible {
    margin-top: 1.8rem;
  }

  @media screen and (max-width: $breakpoint-medium) {
    label {
      padding-bottom: 1rem;
    }
  }
}

.fieldsetLegend {
  display: table; /* reset user agent styles */
  font-size: 1.125rem;
  font-weight: bold;
  margin: 1rem 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

fieldset.row {
  border: 0;
  padding: 0.01em 0 0 0; /* reset user agent styles */
  margin: 0 0 1rem 0;
  min-width: 0;
}

/* reset user agent styles */
body:not(:-moz-handler-blocked) fieldset.row {
  display: table-cell;
}
