@import 'styles/variables/typography';
@import 'styles/variables/colors';
@import 'src/styles/typography';
@import 'fonts/brandon-text/brandon-text.scss';

* {
  box-sizing: border-box;
  font-family: get('typography.fontFamily');
}

html {
  scroll-behavior: smooth;
}

body {
  color: #343434;
  margin: 0;
  padding: 0;
}

a {
  &:link,
  &:active,
  &:visited {
    color: black;
  }
  &:hover {
    text-decoration: none;
  }
}

#launcher {
  visibility: hidden;
  pointer-events: none;

  #web-product &,
  #app-ios-product &,
  #app-android-product &,
  #web-success & {
    visibility: visible;
    pointer-events: all;
  }
}
