@import 'src/styles/variables/spacings';
@import 'src/styles/variables/breakpoints';

.container {
  @include remove-page-spacing();
  width: calc(100% + 32px);

  @media screen and (min-width: 375px) {
    width: calc(100% + 64px);
  }

  picture {
    display: block;
    width: 100%;
    pointer-events: none;
  }

  img {
    width: 100%;
  }
}
