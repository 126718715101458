@import '../variables/typography';
@import '../variables/breakpoints';

@mixin fontWeight($type: body) {
  @if $type == body {
    font-weight: $font-weight;
    @media screen and (min-width: $breakpoint-medium) {
      font-weight: $font-weight-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
      font-weight: $font-weight-large;
    }
  }
  @if $type == paragraph {
    font-weight: $font-weight-paragraph;
    @media screen and (min-width: $breakpoint-medium) {
      font-weight: $font-weight-paragraph-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
      font-weight: $font-weight-paragraph-large;
    }
  }
  @if $type == h1 {
    font-weight: $font-weight-h1;
    @media screen and (min-width: $breakpoint-medium) {
      font-weight: $font-weight-h1-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
      font-weight: $font-weight-h1-large;
    }
  }
  @if $type == h2 {
    font-weight: $font-weight-h2;
    @media screen and (min-width: $breakpoint-medium) {
      font-weight: $font-weight-h2-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
      font-weight: $font-weight-h2-large;
    }
  }
  @if $type == h3 {
    font-weight: $font-weight-h3;
    @media screen and (min-width: $breakpoint-medium) {
      font-weight: $font-weight-h3-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
      font-weight: $font-weight-h3-large;
    }
  }
  @if $type == h4 {
    font-weight: $font-weight-h4;
    @media screen and (min-width: $breakpoint-medium) {
      font-weight: $font-weight-h4-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
      font-weight: $font-weight-h4-large;
    }
  }
}
