@import 'src/styles/variables/breakpoints';

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto -16px auto;
  width: 100%;
}

.actions {
  padding-bottom: 17px;
  width: 100%;
}

.formField {
  width: 100%;
}
