@import 'src/styles/variables/colors';
@import 'src/styles/variables/page';
@import 'src/styles/variables/spacings';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/fontWeights';

.container {
  @include double-spacing(bottom, padding);
  @include large-spacing(top, padding);
  @include page-spacing(padding);
  background: $form-background;
  box-shadow: 0 10px 20px 0 rgba(163, 163, 163, 0.5);
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 910px;
  padding: 1rem 0;
  width: 100%;

  @media screen and (min-width: $breakpoint-medium) {
    flex-direction: row;
    padding: 5rem 0;
  }
}

.imageWrapper {
  order: 1;
}

.image {
  width: 100%;

  @media screen and (min-width: $breakpoint-medium) {
    margin-right: 25px;
    width: 430px;
  }
}

.text {
  order: 2;
  @media screen and (min-width: $breakpoint-medium) {
    margin-left: 25px;
  }
}

.lead {
  font-size: 1.5rem;
}
