$fontWeightThin: get('fontWeight.thin');
$fontWeightLight: get('fontWeight.light');
$fontWeightRegular: get('fontWeight.regular');
$fontWeightMedium: get('fontWeight.medium');
$fontWeightBold: get('fontWeight.bold');
$fontWeightBlack: get('fontWeight.black');

$fontWeightNormal: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;
$fontWeightBlack: 900;
$fontWeightLight: 200;
$fontWeightThin: 100;
