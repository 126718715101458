.input {
  padding: 0 30px 0 40px;
  margin: 0 0 1.5rem;
  color: white;
  font-size: 1rem;
  display: inline-block;
  height: 36px;
  -webkit-appearance: none;
  background: url('Globe.svg') no-repeat 10px center,
    url('Dropdown.svg') no-repeat right 53%;
  border: none;

  transition: background-color 0.1s;

  &:hover {
    background-color: #ffffff22;
  }

  &:focus option {
    color: black;
    background: white;
  }

  &::-ms-expand {
    display: none;
  }
}
