@import 'src/styles/variables/spacings';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';

$break-to-line: 715px;

.badgeWrapper {
  background: $form-background;
  @media screen and (min-width: $breakpoint-medium) {
    padding-bottom: 0;
    position: relative;
    z-index: 25;
  }
}

.lineWrapper {
  @media screen and (min-width: $break-to-line) {
    margin-top: 1rem;
  }
}

.promo {
  padding: $single-margin;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  background: $ms-promo;
  display: flex;
  align-items: center;
  color: $ms-white;

  @media screen and (min-width: 375px) {
    padding-left: $double-margin;
    padding-right: $double-margin;
  }
}

.badgePromo {
  @media screen and (min-width: $breakpoint-medium) {
    background: none;
    color: #343434;
    flex-direction: column;
    padding: 0;
    align-items: flex-start;
    margin-left: 2rem;
  }
}

.linePromo {
  @media screen and (min-width: $break-to-line) {
    position: relative;
    background: none;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;

    &::after {
      position: absolute;
      left: 50%;
      width: 100vw;
      height: 100%;
      top: 0;
      transform: translateX(-50%);
      background: $ms-promo;
      display: block;
      content: '';
      z-index: -1;
    }
  }
}

.priceBox {
  margin-left: auto;
  text-align: right;
  padding-left: $single-margin;

  @media screen and (min-width: $breakpoint-medium) {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
  }
}

.linePriceBox {
  @media screen and (min-width: $break-to-line) {
    margin-left: 0;
    display: flex;
    align-items: baseline;
  }
}

.title {
  font-size: 1.43em;
  font-weight: bold;

  > svg {
    display: none;
  }
}

.badgeTitle {
  @media screen and (min-width: $breakpoint-medium) {
    color: white;
    font-size: 1.4rem;
    margin-left: 0;
    padding: 0 2.4em 0 0;
    height: 45px;
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    position: relative;
    z-index: 3;

    > span {
      position: relative;
      z-index: 2;
    }

    > svg {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: auto;
      backface-visibility: hidden;
    }

    &::before {
      position: absolute;
      right: 100%;
      width: 50vw;
      top: 0;
      content: '';
      display: block;
      height: 100%;
      background: $ms-promo;
    }
  }
}

.lineTitle {
  @media screen and (min-width: $break-to-line) {
    font-size: 1.3em;
  }
}

.price {
  font-size: 1.43em;
  font-weight: $font-weight-h1;
}

.badgePrice {
  @media screen and (min-width: $breakpoint-medium) {
    font-size: 1.43em;
  }
}

.linePrice {
  @media screen and (min-width: $break-to-line) {
    font-size: 1.3em;
    margin-left: 3em;
  }
}

.priceText {
  padding-left: 0.2em;
  font-size: 0.9em;
}

.badgePriceText {
  @media screen and (min-width: $breakpoint-medium) {
    padding-left: 0.3em;
    font-size: 1.1em;
    padding-top: 0.2em;
  }
}

.linePriceText {
  @media screen and (min-width: $break-to-line) {
    font-size: 1.2em;
  }
}
