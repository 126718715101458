@import 'src/styles/variables/spacings';
@import 'src/styles/variables/colors';
@import 'src/styles/fontWeights';
@import 'src/styles/mixins/fontSize';

.unitList {
  display: flex;
  justify-content: space-around;
  @include double-spacing(top, padding);
  max-width: 340px;
  margin: 0 auto;
}

.unitItem {
  max-width: 115px;
  text-align: center;
  position: relative;
  @include single-spacing(bottom);
}

.unitItemHusk {
  width: 100%;
  display: block;
}

.unitExampleValues {
  position: absolute;
  top: 22%;
  left: 10%;
  right: 10%;
  padding: 18% 0;
  background: #d8d8d8;
  font-weight: bold;
  font-size: 1.2rem;
}

.unitValueUnits {
  position: absolute;
  bottom: 18%;
  font-size: 1.2rem;
  left: 0;
  right: 0;
  font-weight: bold;
}

.ctaButton {
  @include fontSize(body, 1.1875);
  font-weight: $fontWeightBold;
}

.unitSelect {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @include single-spacing(bottom);
  margin: 0 5px;
  border-radius: 3px;
  padding: 3px;
  border: none;
  background: none;
  text-decoration: none;

  &:active {
    background: rgba(0, 0, 0, 0.06);
  }
}
