@import 'src/styles/variables/colors';
@import 'src/styles/variables/typography';
@import 'src/styles/mixins/fontFamily';
@import 'src/styles/mixins/fontSize';
@import 'src/styles/mixins/fontWeight';

.button {
  @include fontFamily(body);
  @include fontSize(body);
  @include fontWeight(body);

  background-color: $ms-green;
  border-radius: 6px;
  border: 0;
  color: $ms-white;
  cursor: pointer;
  line-height: 36px;
  min-width: 72px;
  padding: 0 8px;
  position: relative;
  transition: background-color 0.1s ease-in;

  &::before {
    border-radius: 6px;
    border: 2px solid $ms-green;
    content: ' ';
    height: calc(100% - 8px);
    left: 2px;
    position: absolute;
    top: 2px;
    transition: border-color 0.1s ease-in;
    width: calc(100% - 8px);
  }

  &:hover {
    background-color: $ms-tree;

    &::before {
      border-color: $ms-tree;
    }
  }

  &.focus,
  &:focus {
    background-color: $ms-green;

    &::before {
      border-color: $ms-white;
    }
  }

  &.disabled {
    background-color: $ms-dim;
    cursor: not-allowed;

    &::before {
      border-color: $ms-dim;
    }

    &.line {
      background-color: transparent;
      color: $ms-noon;

      &::before {
        border-color: $ms-noon;
      }
    }

    &.text {
      background-color: transparent;
      color: $ms-noon;

      &::before {
        border-color: transparent;
      }
    }

    &.inverse {
      background-color: $ms-white;
      color: $ms-noon;

      &::before {
        border-color: $ms-white;
      }
    }
  }
}

.large {
  @include fontSize(body, 1.125);

  line-height: 44px;
  min-width: 88px;
  padding-left: 10px;
  padding-right: 10px;
}

.slim {
  @include fontSize(body, 0.875);

  line-height: 30px;
}

.line {
  background-color: transparent;
  color: $ms-green;

  &:hover {
    background-color: $ms-green-10;

    &::before {
      border-color: $ms-green;
    }
  }

  &.focus,
  &:focus {
    background-color: transparent;
    color: $ms-tree;

    &::before {
      border-color: $ms-tree;
    }
  }
}

.text {
  background-color: transparent;
  color: $ms-green;

  &::before {
    border-color: transparent;
  }

  &:hover {
    background-color: transparent;
    color: $ms-tree;

    &::before {
      border-color: transparent;
    }
  }

  &.focus,
  &:focus {
    background-color: transparent;

    &::before {
      border-color: transparent;
    }
  }
}

.inverse {
  background-color: $ms-white;
  color: $ms-green;

  &::before {
    border-color: $ms-white;
  }

  &:hover {
    background-color: $ms-white;
    color: $ms-tree;

    &::before {
      border-color: $ms-white;
    }
  }

  &.focus,
  &:focus {
    background-color: $ms-white;
  }
}

.secondary {
  background-color: $ms-orange;

  &::before {
    border-color: $ms-orange;
  }

  &:hover {
    background-color: $ms-dark-orange;

    &::before {
      border-color: $ms-dark-orange;
    }
  }

  &.focus,
  &:focus {
    background-color: $ms-orange;

    &::before {
      border-color: $ms-white;
    }
  }

  &.line {
    background-color: transparent;
    color: $ms-orange;

    &:hover {
      background-color: $ms-orange-10;
    }

    &.focus,
    &:focus {
      color: $ms-dark-orange;

      &::before {
        border-color: $ms-dark-orange;
      }
    }
  }

  &.text {
    background-color: transparent;
    color: $ms-orange;

    &::before {
      border-color: transparent;
    }

    &:hover {
      color: $ms-dark-orange;
    }
  }
}

.tertiary {
  background-color: $ms-petrol;

  &::before {
    border-color: $ms-petrol;
  }

  &:hover {
    background-color: $ms-dark-petrol;

    &::before {
      border-color: $ms-dark-petrol;
    }
  }

  &.focus,
  &:focus {
    background-color: $ms-petrol;

    &::before {
      border-color: $ms-white;
    }
  }

  &.line {
    background-color: transparent;
    color: $ms-petrol;

    &:hover {
      background-color: $ms-petrol-10;
    }

    &.focus,
    &:focus {
      color: $ms-dark-petrol;

      &::before {
        border-color: $ms-dark-petrol;
      }
    }
  }

  &.text {
    background-color: transparent;
    color: $ms-petrol;

    &::before {
      border-color: transparent;
    }

    &:hover {
      color: $ms-dark-petrol;
    }
  }
}

.grey {
  background-color: $ms-daytime;
  color: $ms-midnight;

  &:before {
    border-color: $ms-daytime;
  }

  &:hover {
    background-color: $ms-noon;

    &::before {
      border-color: $ms-noon;
    }
  }

  &.focus,
  &:focus {
    background-color: $ms-daytime;

    &::before {
      border-color: $ms-white;
    }
  }

  &.line {
    background-color: transparent;
    color: $ms-midnight;

    &:hover {
      background-color: $ms-shady;
    }

    &.focus,
    &:focus {
      color: $ms-midnight;

      &::before {
        border-color: $ms-noon;
      }
    }
  }

  &.text {
    background-color: transparent;
    color: $ms-midnight;

    &::before {
      border-color: transparent;
    }

    &:hover {
      color: black;
    }
  }
}
