@import 'src/styles/variables/spacings';
@import 'src/styles/variables/colors';
@import 'src/styles/fontWeights';

.headline {
  font-size: 1.25rem;
  font-weight: $fontWeightBold;
  text-align: center;
}

.headline-dark-bolus {
  color: $ms-dark-bolus;
}

.headline-radish {
  color: $ms-radish;
}

.headline-tree {
  color: $ms-tree;
}

.cart {
  @include small-tiny-spacing();
  list-style: none;
  padding: 0;

  &:before {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    content: '';
    display: block;
    height: 6px;
    width: 100%;
  }
}

.cart-dark-bolus {
  &:before {
    background: $ms-dark-bolus;
  }
}

.cart-radish {
  &:before {
    background: $ms-radish;
  }
}

.cart-tree {
  &:before {
    background: $ms-tree;
  }
}

.item {
  @include large-single-spacing(bottom, padding);
  @include large-single-spacing(top, padding);
  @include large-spacing(left, padding);
  @include large-spacing(right, padding);
  background: $form-background;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1px;

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-bottom: 0;
  }

  &.whiteBg {
    background: $ms-white;
  }
}

.itemName {
  font-size: 1.125rem;
  font-weight: $fontWeightBold;
}

.itemImage {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;

  img {
    max-width: 90px;
  }
}
