@import 'src/styles/variables/spacings';
@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';

.container {
  @include double-spacing(bottom, padding);
  @include page-spacing(padding);
  @include single-spacing(bottom, padding);
  @include medium-spacing(top, padding);
  background-color: $form-background;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  min-height: calc(100vh - 366px);
  padding-bottom: 4rem;
}

.groupWrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: $breakpoint-medium) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
}

.title {
  text-align: center;
  font-size: 1.5rem;
  line-height: 1;
  margin: 0.8em 0 1em;

  @media screen and (min-width: $breakpoint-medium) {
    font-size: 2.2rem;
  }
}
