.container {
  padding: 0;
}

.content {
  min-height: calc(100vh - 306px);
}

.buildInfo {
  font-size: 12px;
  text-align: right;
}
