@import 'src/styles/variables/spacings';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/colors';
@import 'src/styles/variables/page';

$supported-grid: grid;

.container {
  @include page-spacing();
  @include double-spacing(bottom, padding);
  @include double-spacing(top, padding);

  max-width: $form-page-width;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: $breakpoint-medium) {
    margin-left: auto;
    margin-right: auto;
  }
}

.title {
  @include large-single-spacing(bottom);
  font-size: 28px;
  line-height: 32px;
  text-align: center;
}

.listOneItem {
  list-style: none;
  max-width: 100%;
  margin: 0;
  padding: 0;

  @media screen and (min-width: $breakpoint-medium) {
    max-width: 48%;
  }
}

.list {
  display: grid;
  grid-column-gap: $large-margin;
  grid-row-gap: 40px;
  grid-template-columns: 1fr;
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: $breakpoint-medium) {
    grid-row-gap: $single-margin;
    grid-template-columns: repeat(2, 1fr);
  }
}

.item {
  margin-bottom: 40px;

  &--subscriptions {
    order: 3;
  }

  &--immediates {
    order: 1;
  }

  &--products {
    order: 2;

    @media screen and (min-width: $breakpoint-medium) {
      grid-row-end: span 3;
    }
  }

  @supports (display: $supported-grid) {
    margin-bottom: 0;
  }
}
