@import 'src/styles/fontWeights';

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: $fontWeightNormal;
  font-style: normal;
  src: url(./brandon-text.eot);
  src: url(./brandon-text.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text.woff2) format('woff2'),
    url(./brandon-text.woff) format('woff'),
    url(./brandon-text.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: $fontWeightNormal;
  font-style: italic;
  src: url(./brandon-text-italic.eot);
  src: url(./brandon-text-italic.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-italic.woff2) format('woff2'),
    url(./brandon-text-italic.woff) format('woff'),
    url(./brandon-text-italic.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: $fontWeightMedium;
  font-style: normal;
  src: url(./brandon-text-medium.eot);
  src: url(./brandon-text-medium.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-medium.woff2) format('woff2'),
    url(./brandon-text-medium.woff) format('woff'),
    url(./brandon-text-medium.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: $fontWeightMedium;
  font-style: italic;
  src: url(./brandon-text-medium-italic.eot);
  src: url(./brandon-text-medium-italic.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-medium-italic.woff2) format('woff2'),
    url(./brandon-text-medium-italic.woff) format('woff'),
    url(./brandon-text-medium-italic.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: $fontWeightBold;
  font-style: normal;
  src: url(./brandon-text-bold.eot);
  src: url(./brandon-text-bold.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-bold.woff2) format('woff2'),
    url(./brandon-text-bold.woff) format('woff'),
    url(./brandon-text-bold.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: $fontWeightBold;
  font-style: italic;
  src: url(./brandon-text-bold-italic.eot);
  src: url(./brandon-text-bold-italic.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-bold-italic.woff2) format('woff2'),
    url(./brandon-text-bold-italic.woff) format('woff'),
    url(./brandon-text-bold-italic.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: $fontWeightBlack;
  font-style: normal;
  src: url(./brandon-text-black.eot);
  src: url(./brandon-text-black.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-black.woff2) format('woff2'),
    url(./brandon-text-black.woff) format('woff'),
    url(./brandon-text-black.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: $fontWeightBlack;
  font-style: italic;
  src: url(./brandon-text-black-italic.eot);
  src: url(./brandon-text-black-italic.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-black-italic.woff2) format('woff2'),
    url(./brandon-text-black-italic.woff) format('woff'),
    url(./brandon-text-black-italic.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: $fontWeightLight;
  font-style: normal;
  src: url(./brandon-text-light.eot);
  src: url(./brandon-text-light.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-light.woff2) format('woff2'),
    url(./brandon-text-light.woff) format('woff'),
    url(./brandon-text-light.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: $fontWeightLight;
  font-style: italic;
  src: url(./brandon-text-light-italic.eot);
  src: url(./brandon-text-light-italic.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-light-italic.woff2) format('woff2'),
    url(./brandon-text-light-italic.woff) format('woff'),
    url(./brandon-text-light-italic.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: $fontWeightThin;
  font-style: normal;
  src: url(./brandon-text-thin.eot);
  src: url(./brandon-text-thin.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-thin.woff2) format('woff2'),
    url(./brandon-text-thin.woff) format('woff'),
    url(./brandon-text-thin.ttf) format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Brandon Text';
  font-weight: $fontWeightThin;
  font-style: italic;
  src: url(./brandon-text-thin-italic.eot);
  src: url(./brandon-text-thin-italic.eot?#iefix) format('embedded-opentype'),
    url(./brandon-text-thin-italic.woff2) format('woff2'),
    url(./brandon-text-thin-italic.woff) format('woff'),
    url(./brandon-text-thin-italic.ttf) format('truetype');
}
