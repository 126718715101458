.container {
  bottom: 0;
  left: 50%;
  margin-bottom: 0.7rem;
  transform: translateX(-50%);
  position: fixed;
  width: calc(100% - 20px);
  max-width: 500px;
  z-index: 1001;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}
