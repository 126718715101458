@import '/src/styles/fontWeights';
@import '/src/styles/mixins/lineHeight';
@import '/src/styles/variables/_spacings';
@import '/src/styles/variables/_breakpoints';
@import '/src/styles/variables/_colors';

.list {
  @include double-spacing(top);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  list-style: none;
  padding: 0;
  margin: 0;

  // Select the last child when there are 2 or 5 elements in the list
  li:first-child:nth-last-child(2) ~ li:last-of-type,
  li:first-child:nth-last-child(5) ~ li:last-of-type {
    margin-left: -50px;
  }

  @media screen and (min-width: $breakpoint-medium) {
    margin-bottom: 0;

    li:first-child:nth-last-child(5) ~ li:last-of-type {
      margin-left: -70px;
    }
  }
}

.item {
  @include double-spacing(bottom, padding);
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  width: 33%;

  @media screen and (min-width: $breakpoint-medium) {
    padding-bottom: 45px;
  }
}

.itemIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $ms-brand-85;
  width: 20vw;
  height: 20vw;
  min-width: 80px;
  min-height: 80px;
  max-width: 110px;
  max-height: 110px;

  picture {
    display: flex;
    justify-content: center;
    align-content: center;
    flex: 1 1 auto;

    img {
      @media screen and (max-width: $breakpoint-medium) {
        max-width: 60px;
      }
    }
  }
}

.itemName {
  @include medium-small-spacing();
  font-weight: $fontWeightBold;
  line-height: 1.2;
  text-align: center;
  max-width: 125px;
}
