$move: 300%;
$easing: cubic-bezier(0.39, 0.575, 0.565, 1);

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-$move);
  }
}

.container {
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1999999;
}

.box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  width: 160px;
  max-width: 80%;
}

.circle {
  width: 15%;
  background: #0b8bd7;
  border-radius: 50%;

  &::after {
    padding-bottom: 100%;
    content: '';
    display: flex;
  }
}

.circle1 {
  animation: jump $easing 0.5s infinite alternate;
  animation-delay: 0.5s;
  background-color: #8eb927;
}
.circle2 {
  animation: jump $easing 0.5s infinite alternate;
  animation-delay: 0.375s;
  background-color: #e86a0f;
}
.circle3 {
  animation: jump $easing 0.5s infinite alternate;
  animation-delay: 0.25s;
  background-color: #a53257;
}
.circle4 {
  animation: jump $easing 0.5s infinite alternate;
  animation-delay: 0.125s;
  background-color: #479d95;
}
