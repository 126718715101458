@use "sass:math";

$baseFontSize: 16;

@function rem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return math.div($pixels, $context) * 1rem;
}

$regular-weight: 400;
$medium-weight: 500;
$brandon-text: 'Brandon Text';
$font-size: 16px;

$font-family: $brandon-text;
$font-size: 16px;
$font-weight: 400;
$line-height: 1;

$font-size-medium: rem($font-size);
$font-weight-medium: $font-weight;

$font-size-large: rem(17px);
$font-weight-large: $font-weight;

$font-size-paragraph: rem($font-size);
$font-weight-paragraph: $font-weight;
$line-height-paragraph: 1.5;

$font-size-h1: rem(34px);
$font-weight-h1: 500;
$line-height-h1: 1.2;

$font-size-h2: rem(24px);
$font-weight-h2: 500;
$line-height-h2: 1.2;

$font-size-h3: rem(21px);
$font-weight-h3: 500;
$line-height-h3: 1.2;

$font-size-h4: rem(17px);
$font-weight-h4: 500;
$line-height-h4: 1.5;

$font-size-paragraph-medium: rem($font-size);
$font-weight-paragraph-medium: $font-weight;
$line-height-paragraph-medium: 1.5;

$font-size-h1-medium: rem(36px);
$font-weight-h1-medium: 500;
$line-height-h1-medium: 1.2;

$font-size-h2-medium: rem(26px);
$font-weight-h2-medium: 500;
$line-height-h2-medium: 1.5;

$font-size-h3-medium: rem(21px);
$font-weight-h3-medium: 500;
$line-height-h3-medium: 1.5;

$font-size-h4-medium: rem(17px);
$line-height-h4-medium: 1.5;
$font-weight-h4-medium: 500;

$font-size-paragraph-large: rem(17px);
$font-weight-paragraph-large: 400;
$line-height-paragraph-large: 1.5;

$font-size-h1-large: rem(42px);
$font-weight-h1-large: 500;
$line-height-h1-large: 1.2;

$font-size-h2-large: rem(32px);
$font-weight-h2-large: 500;
$line-height-h2-large: 1.2;

$font-size-h3-large: rem(24px);
$font-weight-h3-large: 500;
$line-height-h3-large: 1.2;

$font-size-h4-large: rem(20px);
$font-weight-h4-large: 500;
$line-height-h4-large: 1.5;

$paragraph-h1-margin-large: rem(22px);
$paragraph-h2-margin-large: rem(12px);
$paragraph-h3-margin-large: rem(6px);
$paragraph-h4-margin-large: rem(4px);
$paragraph-p-margin-large: rem(16px);
