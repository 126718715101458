@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/colors';
@import 'src/styles/variables/spacings';
@import 'src/styles/fontWeights';
@import 'src/styles/mixins/fontSize';
@import 'src/styles/mixins/lineHeight';

.wrap {
  max-width: 370px;
  width: 100%;

  &:not(:last-child) {
    @include large-spacing(bottom);
  }

  @media screen and (min-width: $breakpoint-medium) {
    margin: 0 30px;
  }
}

.inner {
  background: $ms-white;
  border-radius: 6px;
  border-top: 6px solid $ms-green-dark;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.15), 0 2px 2px 0 rgba(65, 64, 64, 0.24);
  padding: $single-margin;

  // remove this line if a group neither has the green text nor hasMoreInfo - and calculate it somehow
  padding-bottom: 0;

  &.withoutMoreInfo {
    padding-bottom: $large-single-margin;
  }

  @media screen and (min-width: $breakpoint-small) {
    padding-left: $large-single-margin;
    padding-right: $large-single-margin;
  }
}

.title {
  color: $ms-tree;
  font-size: 1.3125rem;
  font-weight: $fontWeightBold;
  margin: $small-margin 0;
  text-align: center;
}

.image {
  padding: 0 35px;
  width: 100%;
  align-items: center;
  justify-content: center;

  picture {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 220px;
    margin: 0 auto;
    padding-top: 20px;

    @media screen and (min-width: $breakpoint-medium) {
      height: 160px;
    }
  }

  img {
    display: block;
    width: 100%;
  }
}

.price {
  font-size: 1.4rem;
  padding: 0.6rem 0.9rem 0.3rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 1rem;

  @media screen and (min-width: 414px) {
    font-size: 1.7rem;
  }

  @media screen and (min-width: $breakpoint-medium) {
    flex-direction: row;
  }
}

.bundleFree {
  font-weight: 500;
}

.bundlePrice {
  margin-right: 0.2rem;
}

.priceTime {
  font-size: 0.8rem;

  @media screen and (min-width: $breakpoint-medium) {
    padding-left: 0.5rem;
  }

  @media screen and (min-width: 414px) {
    font-size: 1rem;
  }
}

.imagePriceWrap {
  @media screen and (max-width: $breakpoint-medium - 1) {
    display: flex;

    .image {
      width: 55%;
      padding: 0 25px;
    }

    .price {
      padding-left: 15px;
    }
  }
}

.spacer {
  border: none;
  border-top: 1px solid #d8d8d8;
  margin: $large-single-margin 0;
}

.spacerSpacingTop {
  margin: $large-single-margin 0 0 0;
}

.link {
  display: block;
  width: 100%;
}

.linkButton {
  @include fontSize(body, 1.1875);
  width: 100%;
  font-weight: $fontWeightBold;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0 $small-margin;
  font-size: 16px;
  @include lineHeight(paragraph);
}

.listItem {
  display: flex;
  margin-bottom: $small-margin;

  @media screen and (min-width: $breakpoint-medium) {
    align-items: center;
    font-size: 1rem;
    margin-bottom: $single-margin;
  }
}

.itemIcon {
  display: block;
  width: 36px;
  text-align: center;
  margin-right: $single-margin;

  img {
    width: 36px;
  }

  @media screen and (min-width: $breakpoint-medium) {
    min-width: 70px;
    margin-right: $single-margin;

    img {
      width: auto;
    }
  }
}

.message,
.reimbursementWrap {
  @include lineHeight(paragraph);
  background: #eaf1d7;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 14px;
  margin-left: -$single-margin;
  margin-right: -$single-margin;
  margin-top: $large-single-margin;
  padding: $large-single-margin $single-margin;
  text-align: center;

  @media screen and (min-width: $breakpoint-small) {
    margin-left: -$large-single-margin;
    margin-right: -$large-single-margin;
    padding-left: $large-single-margin;
    padding-right: $large-single-margin;
  }

  &.hasMore {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.noMessage {
  padding-top: $large-single-margin;
}

.more {
  border: none;
  background: none;
  width: 100%;
  color: $ms-orange;
  cursor: pointer;
  display: block;
  font-size: 19px;
  font-weight: bold;
  letter-spacing: 0.02em;
  padding: $large-margin $single-margin;
  text-align: center;
  text-decoration: none;

  &:active,
  &:visited {
    box-shadow: 0 0 1px 2px;
  }
}

.buttonWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.removeCartPadding {
  margin-left: auto;
  margin-right: auto;
}
