@import 'src/styles/variables/spacings';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/colors';
@import 'src/styles/fontWeights';
@import 'src/styles/variables/typography';
@import 'src/styles/mixins/lineHeight';

$header-box-shadow: 0 10px 10px 0 rgba(163, 163, 163, 0.5);

$supported-grid: grid;

@mixin header-shadow() {
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 25px;
  z-index: 10;

  @media screen and (min-width: $breakpoint-medium) {
    padding-bottom: 60px;
  }

  &::before {
    background: $form-background;
    bottom: 0;
    content: '';
    display: block;
    height: 25px;
    left: -25px;
    position: absolute;
    width: calc(100% + 50px);
    z-index: 10;
  }

  &::after {
    bottom: 0;
    box-shadow: $header-box-shadow;
    content: '';
    display: block;
    height: 10px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 9;
  }
}

.wrapper {
  display: block;

  @media screen and (min-width: $breakpoint-medium) {
    background: $form-background;
  }

  @supports (display: $supported-grid) {
    background: none;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 910px;

  @supports (display: $supported-grid) {
    display: grid;
    overflow: hidden;
    grid-template-areas:
      'bundleImage bundleImage'
      'price price'
      'priceInfo paymentLogos'
      'topCta topCta'
      'stepBar stepBar'
      'remainingSteps remainingSteps'
      'bundleDescription bundleDescription'
      'bundleContent bundleContent'
      'bundleFeatures bundleFeatures'
      'bottomCta bottomCta';
    grid-template-columns: 59% 1fr;
    max-width: none;

    &.hasCoachOnly {
      grid-template-areas:
        'bundleImage bundleImage'
        'form form'
        'priceInfo paymentLogos'
        'stepBar stepBar'
        'remainingSteps remainingSteps'
        'bundleDescription bundleDescription'
        'bundleContent bundleContent'
        'bundleFeatures bundleFeatures'
        'bottomCta bottomCta';
    }

    &.hasForm {
      grid-template-areas:
        'bundleImage bundleImage'
        'form form'
        'priceInfo paymentLogos'
        'stepBar stepBar'
        'remainingSteps remainingSteps'
        'bundleDescription bundleDescription'
        'bundleContent bundleContent'
        'bundleFeatures bundleFeatures'
        'bottomCta bottomCta';
    }

    &.hasInsuranceNumberForm {
      grid-template-areas:
        'bundleImage bundleImage'
        'form form'
        'price price'
        'priceInfo paymentLogos'
        'stepBar stepBar'
        'remainingSteps remainingSteps'
        'bundleDescription bundleDescription'
        'bundleContent bundleContent'
        'bundleFeatures bundleFeatures'
        'bottomCta bottomCta';
    }
  }

  @media screen and (min-width: $breakpoint-medium) {
    &::after {
      display: block;
      clear: both;
      content: '';
    }
  }
}

@supports (display: $supported-grid) {
  .container {
    @media screen and (min-width: $breakpoint-medium) {
      background: none;
      grid-template-areas:
        'leftBg bundleDescription bundleDescription bundleImage rightBg'
        'leftBg price priceInfo bundleImage rightBg'
        'leftBg topCta topCta bundleImage rightBg'
        'leftBg stepBar stepBar paymentLogos rightBg'
        'leftBg remainingSteps remainingSteps paymentLogos rightBg'
        '. bundleContent bundleContent bundleFeatures .';
      grid-template-columns:
        1fr minmax(min-content, 276px) minmax(min-content, 208px)
        minmax(min-content, 540px) 1fr;

      &.hasForm {
        grid-template-areas:
          'leftBg bundleDescription bundleDescription bundleImage rightBg'
          'leftBg form form form rightBg'
          'leftBg stepBar stepBar stepBar rightBg'
          '. bundleContent bundleContent bundleFeatures .';
      }

      &.hasCoachOnly {
        grid-template-areas:
          'leftBg bundleDescription bundleDescription bundleImage rightBg'
          'leftBg form form bundleImage rightBg'
          'leftBg stepBar stepBar bundleImage rightBg'
          'leftBg stepBar stepBar paymentLogos rightBg'
          '. bundleContent bundleContent bundleFeatures .';
      }

      &.hasInsuranceNumberForm {
        grid-template-areas:
          'leftBg bundleDescription bundleDescription bundleImage rightBg'
          'leftBg form form bundleImage rightBg'
          'leftBg price priceInfo bundleImage rightBg'
          'leftBg stepBar stepBar paymentLogos rightBg'
          '. bundleContent bundleContent bundleFeatures .';
      }
    }
  }
}

.leftBg {
  clear: both;
  display: none;
}

@supports (display: $supported-grid) {
  .leftBg {
    @media screen and (min-width: $breakpoint-medium) {
      @include header-shadow();
      background: $form-background;
      display: block;
      grid-area: leftBg;
    }
  }
}

.rightBg {
  display: none;
}

@supports (display: $supported-grid) {
  .rightBg {
    @media screen and (min-width: $breakpoint-medium) {
      @include header-shadow();
      background: $form-background;
      display: block;
      grid-area: rightBg;
    }
  }
}

.bundleImage {
  @include double-spacing(top, padding);
  @include page-spacing(padding);
  background: $form-background;
  pointer-events: none;

  @media screen and (min-width: $breakpoint-medium) {
    padding-top: 40px;
    float: right;
    width: 50%;
  }

  @supports (display: $supported-grid) {
    grid-area: bundleImage;
    float: none;
    width: auto;
  }
}

.voucherForm {
  @include page-spacing(padding);

  background: $form-background;
  grid-area: form;

  @media screen and (min-width: $breakpoint-medium) {
    display: flex;
    justify-content: center;

    &.hasCoachOnly {
      padding-right: 0;
    }

    .voucherFormWrapper {
      max-width: 322px;
      width: 100%;
    }
  }

  @media screen and (min-width: $breakpoint-large) {
    .voucherFormWrapper {
      max-width: 318px;
    }
  }
}

.insuranceNumberForm {
  @include page-spacing(padding);

  background: $form-background;
  grid-area: form;

  @media screen and (min-width: $breakpoint-medium) {
    display: flex;
    justify-content: center;
    padding-right: 0;

    .voucherFormWrapper {
      max-width: 322px;
      width: 100%;
    }
  }

  @media screen and (min-width: $breakpoint-large) {
    .voucherFormWrapper {
      max-width: 318px;
    }
  }
}

.priceBox {
  background: $form-background;
  display: flex;
  flex-direction: column;
  padding-left: $single-margin;

  @media screen and (min-width: $breakpoint-medium) {
    justify-content: flex-end;
  }

  @media screen and (min-width: 375px) {
    padding-left: $double-margin;
  }

  @supports (display: $supported-grid) {
    grid-area: price;
  }
}

.priceBoxInfo {
  @include lineHeight(paragraph);
  font-size: 0.75rem;
}

.promoPrice {
  padding-bottom: $single-margin;

  @media screen and (min-width: $breakpoint-medium) {
    width: 50%;
  }

  @supports (display: $supported-grid) {
    width: auto;
  }
}

.price {
  @include small-tiny-spacing(top, padding);
  @include small-tiny-spacing(bottom, padding);
  display: flex;
  font-size: $font-size-h1;
  font-weight: $font-weight-h1;
}

.priceInfo {
  @include lineHeight(paragraph);
  background: $form-background;
  font-size: 0.75rem;
  grid-area: priceInfo;
  padding-left: $single-margin;
  margin: 0;
  list-style-type: none;

  li {
    margin: 0;
    padding: 0;
  }

  @media screen and (min-width: 375px) {
    padding-left: $double-margin;
  }

  @media screen and (min-width: $breakpoint-medium) {
    text-align: right;
  }
}

.paymentLogos {
  background: $form-background;
  align-content: flex-end;
  display: flex;
  grid-area: paymentLogos;
  justify-content: flex-end;
  padding-right: $single-margin;

  @media screen and (min-width: 375px) {
    padding-right: $double-margin;
  }

  @media screen and (min-width: $breakpoint-medium) {
    @supports (display: $supported-grid) {
      @include header-shadow();
    }
  }
}

.shadowPlaceholder {
  background: $form-background;
  align-content: flex-end;
  display: flex;
  grid-area: paymentLogos;
  justify-content: flex-end;
  padding-right: $single-margin;

  @media screen and (min-width: 375px) {
    padding-right: $double-margin;
  }

  @media screen and (min-width: $breakpoint-medium) {
    @supports (display: $supported-grid) {
      @include header-shadow();
    }
  }
}

.topCta {
  @include page-spacing(padding);
  @include single-spacing(top, padding);
  background: $form-background;
  grid-area: topCta;

  @media screen and (min-width: $breakpoint-medium) {
    padding-right: 0;
    width: 50%;
  }

  &.hasForm {
    display: none;
  }
}

@supports (display: $supported-grid) {
  .topCta {
    width: auto;
  }
}

.continueButton {
  width: 100%;
}

.stepBar {
  @include page-spacing(padding);
  background: $form-background;
  grid-area: stepBar;
  position: static;

  @media screen and (min-width: $breakpoint-medium) {
    padding-right: 0;
    width: 50%;

    &.hasForm {
      padding-left: 0;
    }
  }
}

@supports (display: $supported-grid) {
  .stepBar {
    position: static;

    @media screen and (min-width: $breakpoint-medium) {
      @include header-shadow();
      width: auto;
    }
  }
}

.remainingSteps {
  @include single-spacing(bottom, padding);
  @include page-spacing(padding);
  background: $form-background;
  box-shadow: $header-box-shadow;
  grid-area: remainingSteps;

  @media screen and (min-width: $breakpoint-medium) {
    display: none;
  }
}

.remainingStepsText {
  @include small-tiny-spacing(top, padding);
  font-size: 0.875rem;
}

.bundleDescription {
  @include page-spacing(padding);
  clear: both;
  font-size: 1.125rem;
  grid-area: bundleDescription;
  padding-top: 60px;
  padding-right: 0.8rem;

  @media screen and (min-width: $breakpoint-medium) {
    background: $form-background;
    padding-bottom: 46px;
    padding-right: 1rem;
    padding-top: $single-margin;

    &.hasCoachOnly {
      @include double-spacing(bottom, padding);
    }
  }
}

.bundleDescriptionExtraSpace {
  @media screen and (min-width: $breakpoint-medium) {
    padding-top: 60px;
  }
}

.bundleContent {
  @include page-spacing(padding);
  @include double-spacing(top, padding);
  clear: both;
  display: flex;
  grid-area: bundleContent;
  justify-content: center;
  position: relative;

  @media screen and (min-width: $breakpoint-medium) {
    float: left;
    width: 50%;
    padding-top: 60px;
    padding-right: 0;
  }
}

@supports (display: $supported-grid) {
  .bundleContent {
    @media screen and (min-width: $breakpoint-medium) {
      float: none;
      width: auto;
    }
  }
  .bundleContentSmall {
    &::before {
      @media screen and (min-width: $breakpoint-medium) {
        height: 260px;
        left: -55px;
      }
    }
  }
}

.bundleContentBackground {
  position: relative;
  width: 100%;
}

.bundleFeatures {
  @include single-spacing(bottom);
  font-size: 1.125rem;
  grid-area: bundleFeatures;

  @media screen and (min-width: $breakpoint-medium) {
    float: right;
    width: 50%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 2rem;
    padding-top: 60px;
  }
}

@supports (display: $supported-grid) {
  .bundleFeatures {
    @include page-spacing();

    @media screen and (min-width: $breakpoint-medium) {
      @include page-spacing();
      float: none;
      width: auto;
    }
  }
  .bundleFeaturesSmall {
    @include single-spacing(bottom);
  }
}

.bundleFeaturesTitle {
  @include large-spacing(bottom);
  display: none;
  font-weight: $fontWeightMedium;
  font-size: 28px;

  @media screen and (min-width: $breakpoint-medium) {
    display: block;
  }
}

.bottomCta {
  @include large-single-spacing(top, padding);
  @include large-single-spacing(bottom, padding);
  @include page-spacing(padding);
  background: $form-background;
  box-shadow: $header-box-shadow;
  grid-area: bottomCta;

  @media screen and (min-width: $breakpoint-medium) {
    display: none;
  }
}

.desktopOnly {
  @media screen and (max-width: $breakpoint-medium) {
    display: none;
  }
}
.mobileOnly {
  @media screen and (min-width: $breakpoint-medium + 1) {
    display: none;
  }
}

/*
 Attribute selector is used here instead of `:global .no-grid`
 because `:global` would then prevent the selectors inside to not
 be parsed into a random string.
*/
body[class*='no-grid'] {
  display: block;

  .bundleFeatures {
    display: flex;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media screen and (min-width: 768px) {
    .container {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .bundleDescription,
    .price,
    .priceInfo,
    .paymentLogos,
    .topCta,
    .stepBar {
      position: relative;
      z-index: 5;
    }

    .bundleDescription {
      order: 1;
      width: 40%;

      &.isPro {
        min-height: 460px;
      }
    }

    .bundleImage {
      order: 2;
      width: 60%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 4;
    }

    .price,
    .priceInfo,
    .priceBox,
    .topCta {
      order: 3;
      width: 50%;
    }

    .priceInfo {
      height: 0;
    }

    .paymentLogos {
      height: 0;
      right: 0;
      order: 4;
      width: 100%;
    }

    .stepBar {
      order: 4;
    }

    .bundleContent {
      width: 50%;
      order: 5;
      position: absolute;
      transform: translateY(100%);
      margin-top: 14rem;
      min-height: 450px;

      &.isPro {
        min-height: 350px;
      }

      &.hasCoachOnly {
        min-height: 0;
      }
    }

    .bundleFeatures {
      display: block;
      width: 45%;
      order: 6;
      margin-left: 55%;
      margin-bottom: 3rem;
      margin-top: 1rem;
      padding-left: 0;
      padding-right: 0;
    }

    .hasForm {
      .voucherForm,
      .insuranceNumberForm {
        order: 3;

        position: relative;
        z-index: 120;
        justify-content: center;
      }

      .stepBar {
        width: 100%;
        order: 4;
      }
    }

    .hasCoachOnly {
      .voucherForm,
      .insuranceNumberForm {
        @include single-spacing(top, margin);
        order: 3;
        z-index: 120;
        width: 50%;
      }

      .stepBar {
        order: 4;
      }
    }

    .isPro {
      .voucherForm {
        width: 89%;
        margin-left: 3px;
      }
    }
  }
}
