@import 'src/styles/variables/spacings';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/colors';

.footer {
  @include large-spacing(bottom, padding);
  @include large-spacing(top, padding);
  background: $ms-midnight;
  color: $ms-white;

  a {
    &:link,
    &:active,
    &:visited {
      color: $ms-white;
    }
  }

  h2 {
    font-size: 1.3rem;
  }

  &--space-bottom {
    padding-bottom: 225px;
    @media (min-width: 404px) {
      padding-bottom: 200px;
    }
    @media (min-width: 375px) {
      padding-bottom: 220px;
    }
    @media (min-width: 717px) {
      padding-bottom: 150px;
    }
  }
}

.container {
  @include page-spacing(padding);
  margin: 0 auto;
  max-width: 1024px;
}

.innerContent {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.support {
  @include large-spacing(bottom, padding);
  margin-top: 0.6rem;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0.4rem 0 0;

    li {
      margin: 0.6rem 0 0;
    }
  }
}

.legalNotice {
  @include double-spacing(bottom, margin);
  @include double-spacing(top, margin);
  font-size: 0.9rem;
}

.links {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;

  @media screen and (min-width: $breakpoint-medium) {
    flex-wrap: nowrap;
  }
}

.link {
  @include single-spacing(top, margin);
  text-align: center;
  padding: 0.2rem 0.2rem;

  a {
    &:link,
    &:active,
    &:visited {
      text-decoration: none;
    }
  }

  @media screen and (min-width: $breakpoint-medium) {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
}

.linkSpacer {
  @include single-spacing(top, margin);
  content: '';
  height: 30px;
  border-right: 1px solid $ms-white;
  width: 1px;
}

.line {
  height: 1px;
  margin: 0;
}
