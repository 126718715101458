.container {
  bottom: 0;
  height: calc(1rem + 26px);
  left: 50%;
  margin-left: -12px;
  padding: 0.5rem;
  position: fixed;
  width: calc(1rem + 24px);
  z-index: 999;
}
.hidden {
  pointer-events: none;
  transition: 0.5s opacity ease-out;
  opacity: 0;
}
