$double-margin: 32px;
$large-margin: 24px;
$large-single-margin: 20px;
$single-margin: 16px;
$medium-margin: 12px;
$medium-small-margin: 10px;
$small-margin: 8px;
$small-tiny-margin: 6px;
$tiny-margin: 4px;
$border-margin: 2px;

@mixin page-spacing($type: margin) {
  #{$type}-left: $single-margin;
  #{$type}-right: $single-margin;

  @media screen and (min-width: 375px) {
    #{$type}-left: $double-margin;
    #{$type}-right: $double-margin;
  }
}

@mixin remove-page-spacing($type: margin) {
  #{$type}-left: $single-margin * -1;
  #{$type}-right: $single-margin * -1;

  @media screen and (min-width: 375px) {
    #{$type}-left: $double-margin * -1;
    #{$type}-right: $double-margin * -1;
  }
}

@mixin double-spacing($position: top, $type: margin) {
  #{$type}-#{$position}: $double-margin;
}

@mixin large-spacing($position: top, $type: margin) {
  #{$type}-#{$position}: $large-margin;
}

@mixin large-single-spacing($position: top, $type: margin) {
  #{$type}-#{$position}: $large-single-margin;
}

@mixin single-spacing($position: top, $type: margin) {
  #{$type}-#{$position}: $single-margin;
}

@mixin medium-spacing($position: top, $type: margin) {
  #{$type}-#{$position}: $medium-margin;
}

@mixin medium-small-spacing($position: top, $type: margin) {
  #{$type}-#{$position}: $medium-small-margin;
}

@mixin small-spacing($position: top, $type: margin) {
  #{$type}-#{$position}: $small-margin;
}

@mixin small-tiny-spacing($position: top, $type: margin) {
  #{$type}-#{$position}: $small-tiny-margin;
}

@mixin tiny-spacing($position: top, $type: margin) {
  #{$type}-#{$position}: $tiny-margin;
}

@mixin border-spacing($position: top, $type: margin) {
  #{$type}-#{$position}: $border-margin;
}

@mixin no-spacing($position: top, $type: margin) {
  #{$type}-#{$position}: 0;
}
