.container {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    max-width: 37px;
    width: 33%;
  }

  img {
    max-width: 100%;
  }

  &.row {
    justify-content: center;
    max-width: inherit;

    li {
      margin-left: 6px;
      margin-top: 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
