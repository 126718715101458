@import 'src/styles/variables/spacings';
@import 'src/styles/variables/colors';
@import 'src/styles/fontWeights';

.container {
  text-align: left;
  padding: 1.2rem;
  position: relative;
  width: 100%;
  margin-bottom: 0.6rem;
  border-radius: 0.6rem;
  box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.2);

  &.success {
    background: $ms-tree;
    color: $ms-white;
  }

  &.error {
    background: $form-border-error;
    color: $ms-white;
  }
}

.message {
  font-weight: $fontWeightBold;
  margin-right: 1.3rem;
  text-align: center;
}

.dismiss {
  position: absolute;
  background: none;
  border: 0;
  padding: 0;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
