@import 'src/styles/variables/colors';

@mixin inputError() {
  background-color: $form-background-error;
  border: 2px solid $form-border-error;
  border-radius: 6px 6px 0 6px;
  padding: 0 0.8rem;

  &:focus,
  &.focus {
    background-color: $ms-white;
  }
}

@mixin inputWarning() {
  border: 2px solid $ms-purple;
  background: $ms-white;
  padding: 0 0.8rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.container {
  width: 100%;
}

.input {
  appearance: none;
  background-color: $ms-white;
  border-radius: 6px;
  border: 1px solid $form-border;
  font-size: 1rem;
  height: 44px;
  padding: 1px calc(0.8rem + 1px);
  transition-duration: 60ms;
  transition-property: background, border, padding;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &[disabled] {
    background-color: $ms-daytime;
    border-color: $ms-dim;
    color: $ms-twilight;
    -webkit-text-fill-color: $ms-twilight;

    // Needs to be at the end so that error styles overwrite
    // disabled styles
    &.error {
      @include inputError();
    }
  }

  &:focus,
  &.focus {
    background-color: $ms-white;
    border: 2px solid $form-border-focused;
    padding: 0 0.8rem;
    outline: 0;
  }
}

.error {
  @include inputError();
  &:focus,
  &.focus {
    border-color: $form-border-error;
  }
}

.warning {
  @include inputWarning();
}

.success {
  border-color: $ms-tree;
  border-width: 2px;
  padding: 0 0.8rem;

  &:focus {
    border-color: $ms-tree;
  }

  &[disabled] {
    background-color: #f5f5f5;
    border-color: #bdcb9c;
    border-width: 2px;
    color: #a8a8a8;
    -webkit-text-fill-color: #a8a8a8;
  }
}

.verified {
  background-image: url(../../icons/verified.svg);
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  padding-right: calc(0.5rem + 23px + 0.5rem);
}

.icon {
  background-repeat: no-repeat;
  background-position: calc(0.5rem + 1px) center;
  padding-left: calc(0.5rem + 26px + 1px);

  &:focus,
  &.focus {
    background-position-x: 0.5rem;
    padding-left: calc(0.5rem + 26px);
  }
}

.maskText {
  position: absolute;
  padding: 0 0.8rem;
  line-height: 44px;
  pointer-events: none;
  color: #aaa;
}

.iconCard {
  background-image: url(../../icons/card.svg);
}

.iconExpiryDate {
  background-image: url(../../icons/expiry-date.svg);
}

.iconCcv {
  background-image: url(../../icons/ccv.svg);
}
