.offline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.7rem;
  height: 100vh;
  color: #737373;
  font-size: 3.6vw;

  @media (min-width: 480px) {
    font-size: 1rem;
  }
}

.image {
  max-width: 340px;
  width: 80%;
  display: block;
  margin-bottom: 1rem;
}

.title {
  font-size: 2.1em;
  font-weight: normal;
  margin-top: 1rem;
}

.subtitle {
  margin-top: 0.4rem;
  font-size: 1.4em;
  font-weight: normal;
}

.button {
  margin-top: 1.5rem;
  width: 100%;
  max-width: 360px;
}
