@import '../variables/typography';
@import '../variables/breakpoints';

@mixin lineHeight($element: body) {
  @if $element == body {
    line-height: $line-height;
  }
  @if $element == paragraph {
    line-height: $line-height-paragraph;
    @media screen and (min-width: $breakpoint-medium) {
      line-height: $line-height-paragraph-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
      line-height: $line-height-paragraph-large;
    }
  }
  @if $element == h1 {
    line-height: $line-height-h1;
    @media screen and (min-width: $breakpoint-medium) {
      line-height: $line-height-h1-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
      line-height: $line-height-h1-large;
    }
  }
  @if $element == h2 {
    line-height: $line-height-h2;
    @media screen and (min-width: $breakpoint-medium) {
      line-height: $line-height-h2-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
      line-height: $line-height-h2-large;
    }
  }
  @if $element == h3 {
    line-height: $line-height-h3;
    @media screen and (min-width: $breakpoint-medium) {
      line-height: $line-height-h3-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
      line-height: $line-height-h3-large;
    }
  }
  @if $element == h4 {
    line-height: $line-height-h4;
    @media screen and (min-width: $breakpoint-medium) {
      line-height: $line-height-h4-medium;
    }
    @media screen and (min-width: $breakpoint-large) {
      line-height: $line-height-h4-large;
    }
  }
}
