@import 'src/styles/variables/colors';
@import 'src/styles/mixins/fontSize';
@import 'src/styles/variables/breakpoints';

body {
  .link {
    font-size: 17px;
    margin: 0 0 0 0.4em;
  }

  .dvgText {
    font-size: 17px;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: $breakpoint-medium) {
      flex-direction: column;
    }
  }
}

.bundleInfo {
  a {
    color: $ms-green-dark;
  }
}
