@import 'src/styles/variables/spacings';
@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';

$header-box-shadow: 0px 4px 4px rgba(196, 194, 194, 0.5);

.container {
  background: $ms-white;
  box-shadow: $header-box-shadow;
  position: relative;
  text-align: center;
  z-index: 20;
}

.logoImg {
  height: 33px;
  margin-bottom: 2px;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
}

.payorHeart {
  width: 1rem;
  margin: 0 16px 0 16px;

  @media screen and (min-width: $breakpoint-medium) {
    width: 1.2rem;
  }
}

.payorBar {
  margin: 0 18px 0 10px;
}

.payorLogo {
  max-height: 30px;
  max-width: 120px;
}

:global {
  header {
    .mySugrLogo {
      display: block;
      margin-right: 2px;
    }

    //Payor specific logo resize
    #payor-logo-dec_i_1 {
      max-width: 185px;
    }

    #web-product,
    #app-ios-product,
    #app-android-product {
      .mySugrLogo {
        display: block;
      }
    }

    #payor-logo-onemed_1 {
      max-width: 320px;
    }

    #payor-logo-ukv_1 {
      max-height: 21px;
    }

    #payor-logo-schwenninger_1 {
      max-height: 26px;
      position: relative;
      top: 5px;
    }

    #payor-logo-si_group_1 {
      max-height: 20px;
    }

    #payor-logo-barmenia_1 {
      max-height: 40px;
    }

    #payor-logo-wth_1 {
      position: relative;
      top: -3px;
    }

    #payor-logo-aok_nordwest_1 {
      max-height: 32px;
    }

    #payor-logo-dkv_1 {
      max-height: 22px;
    }

    #payor-logo-buchanan_us_1 {
      max-height: 25px;
    }

    #payor-logo-mmc_1 {
      max-height: 24px;
    }

    #payor-logo-genentech_1 {
      max-height: 31px;
    }

    #payor-logo-mastec_1 {
      max-height: 24px;
    }

    #payor-logo-lazer_spot_1 {
      max-height: 23px;
    }

    #payor-logo-roche_ponce,
    #payor-logo-roche_us_1 {
      width: 165px;
    }

    #payor-logo-ed_voyles {
      max-height: 45px;
    }

    @media screen and (max-width: $breakpoint-medium - 1) {
      #payor-logo-jbz_1 {
        max-height: 28px;
      }

      #payor-logo-onemed_1 {
        max-width: 180px;
      }
    }
  }
}
