@import '../variables/typography';

@mixin textMargin($element, $prevElement) {
  @if $element == paragraph {
    @if $prevElement == h1 {
      margin-top: $paragraph-h1-margin-large;
    }
    @if $prevElement == h2 {
      margin-top: $paragraph-h2-margin-large;
    }
    @if $prevElement == h3 {
      margin-top: $paragraph-h3-margin-large;
    }
    @if $prevElement == h4 {
      margin-top: $paragraph-h4-margin-large;
    }
    @if $prevElement == paragraph {
      margin-top: $paragraph-p-margin-large;
    }
  }
}
