@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/colors';

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 -16px 0;
  max-width: 650px;
  width: 100%;

  @media screen and (min-width: $breakpoint-medium) {
    align-items: flex-end;
    flex-direction: row;

    &.hasCoachOnly {
      flex-direction: column;
    }
  }
}

.inputs {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint-medium) {
    flex-direction: row;
    justify-content: space-between;
    max-width: 428px;
    width: 100%;

    &.hasCoachOnly {
      max-width: 451px;
    }
  }
}

.field {
  @media screen and (min-width: $breakpoint-medium) {
    align-self: flex-end;
    width: 100%;
  }

  &.groupIdInputVisible {
    @media screen and (min-width: $breakpoint-medium) {
      width: 206px;

      &.hasCoachOnly {
        width: 217px;
      }
    }
  }
}

.actions {
  margin-top: 16px;
  padding-bottom: 16px;
  width: 100%;

  @media screen and (min-width: $breakpoint-medium) {
    margin-top: 0;

    &.hasCoachOnly {
      max-width: none;
    }
    max-width: 204px;
  }
}

.overlayImg {
  max-width: 315px;
}
