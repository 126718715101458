@import 'src/styles/variables/colors';
@import 'src/styles/fontWeights';

.container {
  text-align: left;

  h4 {
    font-weight: $fontWeightMedium;
    color: $ms-green;
    font-size: 20px;
  }

  p {
    margin: 0.4rem 0 1rem;
  }
}

.link {
  color: $ms-midnight;
  font-weight: bold;
  font-size: inherit;
  margin: 0 0 0 0.4em;
}
