@import 'src/styles/variables/clearfix';
@import 'src/styles/variables/spacings';
@import 'src/styles/variables/colors';
@import 'src/styles/fontWeights';
@import 'src/styles/mixins/lineHeight';

.wrap {
  display: flex;
  align-items: baseline;
}

.formLabel {
  @include border-spacing(bottom);
  @include clearfix();
  @include lineHeight(paragraph);
  display: flex;
  flex-direction: row;
  font-weight: $fontWeightMedium;
  padding-left: 1px;
  padding-right: 1px;
  align-items: baseline;

  &.disabled {
    color: #a4a4a4;
  }
}

.hint {
  color: $ms-midnight;
  font-size: 0.75rem;
  font-weight: $fontWeightRegular;
  line-height: 1.5rem;
  margin-left: auto;
}

.moreInfo {
  white-space: pre;
}

.moreInfo.hint {
  margin: 0;
}

.moreInfoText {
  font-size: inherit;
}

.moreInfoIcon {
  position: relative;
  bottom: -3px;
  margin-left: 10px;
}
