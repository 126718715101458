@import 'src/styles/mixins/fontFamily';
@import 'src/styles/mixins/fontSize';
@import 'src/styles/mixins/lineHeight';
@import 'src/styles/mixins/textMargin';
@import 'src/styles/mixins/fontWeight';

body {
  @include fontFamily(body);
  @include fontSize(body);
  @include fontWeight(body);
  @include lineHeight(body);
}

h1 {
  @include fontSize(h1);
  @include fontWeight(h1);
  @include lineHeight(h1);
  margin: 0;
}

h2 {
  @include fontSize(h2);
  @include fontWeight(h2);
  @include lineHeight(h2);
  margin: 0;
}

h3 {
  @include fontSize(h3);
  @include fontWeight(h3);
  @include lineHeight(h3);
  margin: 0;
}

h4 {
  @include fontSize(h4);
  @include fontWeight(h4);
  @include lineHeight(h4);
  margin: 0;
}

p {
  @include fontSize(paragraph);
  @include fontWeight(paragraph);
  @include lineHeight(paragraph);
  margin: 0;

  h1 + & {
    @include textMargin(paragraph, h1);
  }

  h2 + & {
    @include textMargin(paragraph, h2);
  }

  h3 + & {
    @include textMargin(paragraph, h3);
  }

  h4 + & {
    @include textMargin(paragraph, h4);
  }

  p + & {
    @include textMargin(paragraph, paragraph);
  }
}

input,
select,
textarea {
  @include fontFamily(body);
  @include fontSize(body);
  @include fontWeight(body);
}
