@import 'src/styles/variables/breakpoints';
@import 'src/styles/mixins/fontSize';
@import 'src/styles/fontWeights';

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto -16px auto;
  width: 100%;

  @media screen and (min-width: $breakpoint-medium) {
    align-items: flex-start;
    flex-direction: row;
    max-width: 650px;

    &.onVoucherPage {
      flex-direction: column;
      align-items: center;
    }
  }
}

.actions {
  padding-top: 0;
  padding-bottom: 17px;
  width: 100%;

  @media screen and (min-width: $breakpoint-medium) {
    width: calc(50% - 8px);
    padding-top: 26px;

    &.onVoucherPage {
      padding-top: 0;
    }
  }
}

.formField {
  width: 100%;

  @media screen and (min-width: $breakpoint-medium) {
    width: calc(50% - 8px);
  }
}

.ctaButton {
  @include fontSize(body, 1.1875);
  font-weight: $fontWeightBold;
}
