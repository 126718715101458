@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/page';
@import 'src/styles/variables/spacings';

$headerHeight: 2.6rem;
$offsetTop: 18vh;

.outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  z-index: 999999;
  transition: all 0.4s;

  &.outerHidden {
    pointer-events: none;

    iframe {
      display: none;
    }
  }
}

.outerVisible {
  background: rgba(0, 0, 0, 0.6);
  pointer-events: all;
}

.outerHidden {
  background: none;
  pointer-events: none;
}

.innerWrap {
  position: absolute;
  left: 50%;
  width: 960px;
  max-width: 100%;
  bottom: 0;
  transition: all 0.3s;
}

.innerWrapVisible {
  opacity: 1;
  transform: translateY(0) translateX(-50%);
  position: relative;
}

.innerWrapHidden {
  transform: translateY(30px) translateX(-50%);
  opacity: 0;
}

.inner {
  position: relative;
  margin-top: $offsetTop;
  padding-top: $headerHeight;
  max-height: calc(100vh - #{$headerHeight} + #{$offsetTop});
  background: $ms-white;
  border-radius: 20px 20px 0 0;
  color: #343434;
  line-height: 1.3;
  min-height: 75vh;
  bottom: 0;

  h5 {
    color: $ms-green-dark;
    position: relative;
    padding-left: 18px;

    @media screen and (min-width: $breakpoint-small) {
      padding-left: 0;
    }
  }

  h4 + p {
    margin-bottom: 1em;
    margin-top: 0.8em;
  }

  h4[data-icon] {
    padding-left: 45px;

    &::before {
      background-size: contain;
      background-repeat: no-repeat;
      content: '';
      display: block;
      position: absolute;
      width: 24px;
      height: 35px;
      transform: translateY(8px) translateX(calc(-100% - 20px));
    }
  }

  h4[data-icon] + p {
    padding-left: 45px;
  }

  h4[data-icon='file'] {
    &::before {
      background-image: url('../../icons/file.svg');
    }
  }
  h4[data-icon='log'] {
    &::before {
      background-image: url('../../icons/log.svg');
    }
  }
  h4[data-icon='search'] {
    &::before {
      background-image: url('../../icons/search.svg');
    }
  }
  h4[data-icon='badge'] {
    &::before {
      background-image: url('../../icons/badge.svg');
    }
  }
  h4[data-icon='bs-meter'] {
    &::before {
      background-image: url('../../icons/bs-meter.svg');
    }
  }

  a,
  a:active,
  a:visited,
  a:link {
    color: $ms-green;
    font-weight: bold;
  }
}

.scrollable {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @include page-spacing(padding);
  padding-bottom: 2rem;
  position: relative;
  max-height: calc(100vh - #{$headerHeight} - #{$offsetTop});
  height: 100%;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: $breakpoint-medium) {
    padding: 90px 32px;
  }

  @media screen and (min-width: $breakpoint-large) {
    padding: 80px 64px;
    width: 908px;
  }
}

.close {
  position: absolute;
  z-index: 40;
  top: 0;
  right: 0;
  padding: 15px;
  margin: 5px 5px 0 0;
  border: none;
  background: none;

  &[aria-hidden] {
    display: none;
  }
}

.title {
  font-size: 1.4rem;
  font-weight: normal;
  padding: 0 17px;
}

.textCenter {
  text-align: center;
}
