.translateHtml {
  h2 {
    margin: 1rem 0;
  }

  ul {
    margin: 1rem 0;
  }

  li {
    line-height: 1.5;
  }
}
