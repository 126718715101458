// Primary colors
$ms-green: #72941f;
$ms-midnight: #343434;
$ms-twilight: #737373;
$ms-noon: #a3a3a3;
$ms-dim: #c4c2c2;
$ms-daytime: #e6e6e6;
$ms-shady: #f2f2f2;
$ms-neutral: #fafafa;
$ms-white: #ffffff;
$ms-brand: #8eb927;
$roche-brand: #06c;

// Secondary colors
$ms-red: #e75d32;
$ms-gold: #f0ac00;
$ms-petrol: #479d95;
$ms-dark-petrol: #088e85;
$ms-orange: #e86a0f;
$ms-dark-orange: #e07716;
$ms-purple: #a53257;
$ms-brown: #9e6c3b;
$ms-tree: #72941f;
$ms-blue: #66d4d9;
$ms-radish: #920162;
$ms-dark-bolus: #00809d;
$ms-dark-brand: #72941f;
$ms-honey-85: #fff7d9;

//Gradations of ms-brand
$ms-brand-85: #eef5df;

// Gradations of ms-green
$ms-green-70: #afce67;
$ms-green-50: #c6dc93;
$ms-green-40: #d2e3a9;
$ms-green-20: #e8f1d4;
$ms-green-10: #f3f8e9;

// Gradations of ms-orange
$ms-orange-70: #ffac5e;
$ms-orange-50: #ffc48c;
$ms-orange-20: #ffe7d1;
$ms-orange-10: #fff3e8;

// Gradations of ms-petrol
$ms-petrol-70: #7ebab5;
$ms-petrol-50: #a3ceca;
$ms-petrol-20: #daebea;
$ms-petrol-10: #edf5f4;

$form-background: #efefef;
$form-border: #c4c2c2;
$form-border-focused: #0b8bd7;
$form-border-error: #bc171b;
$form-background-error: rgba(188, 23, 27, 0.1);
$form-radio-border: #b4bfc9;
$dark-grey-color: #737373;

$ms-green-dark: $ms-green;
$ms-error: #d0021b;
$ms-promo: #9a709b;
$ms-gray: #8f8f8f;
