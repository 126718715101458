@import 'src/styles/variables/page';
@import 'src/styles/variables/spacings';
@import 'src/styles/mixins/hideVisually';
@import 'src/styles/mixins/showVisually';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/colors';
@import 'src/styles/fontWeights';

$step-bar-height: 36px;

.container {
  @include single-spacing(top, padding);
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: $breakpoint-medium) {
    max-width: $form-page-width;
  }

  @supports (display: grid) {
    height: $step-bar-height + $single-margin;
  }
}

.imgContainer {
  display: none;

  :global .background,
  :global .left-path,
  :global .middle-path,
  :global .right-path {
    fill: inherit;
  }
}

.list {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.step {
  margin-left: -12px;
  position: relative;
  height: $step-bar-height;
  width: 100%;

  @media screen and (min-width: 375px) {
    margin-left: -12px;
  }

  &.step-5 {
    margin-left: -14px;

    @media screen and (min-width: 375px) {
      margin-left: -12px;
    }
  }

  &:first-child {
    margin-left: 0;
  }
}

.stepBorder {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;

  .step:first-child & {
    left: 0;
  }

  .svg {
    display: inline-block;
    height: $step-bar-height;

    &.svgLeft {
      width: 22px;
    }

    &.svgLeftFirst {
      width: 4px;
    }

    &.svgLeftLast {
      width: 21px;
    }

    &.svgMiddle {
      margin-left: -1px;
      margin-right: -1px;
      width: calc(100% - 22px - 19px);
    }

    &.svgMiddleFirst {
      width: calc(100% - 4px - 19px + 2px);
    }

    &.svgMiddleLast {
      width: calc(100% - 21px - 5px + 2px);
    }

    &.svgRight {
      width: 19px;
    }

    &.svgRightLast {
      width: 5px;
    }

    svg {
      height: $step-bar-height;
      width: 100%;
    }
  }

  .stepBorderLeft,
  .stepBorderMiddle,
  .stepBorderRight {
    fill: #efefef;
  }
}

.done {
  display: block;

  .stepBorderLeft,
  .stepBorderMiddle,
  .stepBorderRight {
    fill: $ms-green-20;
  }
}

.active {
  display: block;

  .stepBorderLeft,
  .stepBorderMiddle,
  .stepBorderRight {
    fill: $ms-tree;
  }
}

.stepLink {
  align-items: center;
  border-radius: 6px;
  color: $ms-twilight;
  display: flex;
  font-weight: $fontWeightBold;
  height: 100%;
  justify-content: center;
  margin-left: 25.23%;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: 0;
  width: 52.87%;
  z-index: 101;

  &:link,
  &:active,
  &:visited {
    color: $ms-twilight;
  }

  .step:first-child & {
    margin-left: 0;
    width: 85%;
  }

  .step:last-child & {
    width: 73.5%;
  }

  @media screen and (min-width: $breakpoint-medium) {
    margin-left: 12%;
    width: 77%;

    .step:first-child & {
      width: 88%;
    }

    .step:last-child & {
      width: 85.5%;
    }
  }
}

.stepNumber {
  display: block;

  .active & {
    color: $ms-white;
  }

  @media screen and (min-width: $breakpoint-medium) {
    @include hideVisually;

    .alwaysShowNumbers & {
      @include showVisually;
    }
  }
}

.stepName {
  @include hideVisually;
  font-weight: $fontWeightMedium;
  font-size: 0.875rem;

  .success & {
    color: $ms-tree;
  }

  .active & {
    color: $ms-white;
  }

  .done & {
    color: $ms-midnight;
  }

  @media screen and (min-width: $breakpoint-medium) {
    @include showVisually;

    svg {
      position: absolute;
      margin-left: 8px;
    }

    .success & {
      padding-right: 24px;
    }

    .alwaysShowNumbers & {
      @include hideVisually;
    }
  }
}

.placeholder {
  height: 0.4rem;
}

.successStepNumber {
  @include hideVisually;
}

.checkmarkColor {
  fill: $ms-tree;

  .active & {
    fill: $ms-white;
  }
}
