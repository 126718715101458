@import '../variables/typography';
@import '../variables/breakpoints';

@mixin fontSize($element: body, $modifier: 1) {
  @if $element == body {
    font-size: $font-size * $modifier;
    @media screen and (min-width: $breakpoint-medium) {
      font-size: $font-size-medium * $modifier;
    }
    @media screen and (min-width: $breakpoint-large) {
      font-size: $font-size-large * $modifier;
    }
  }
  @if $element == paragraph {
    font-size: $font-size-paragraph * $modifier;
    @media screen and (min-width: $breakpoint-medium) {
      font-size: $font-size-paragraph-medium * $modifier;
    }
    @media screen and (min-width: $breakpoint-large) {
      font-size: $font-size-paragraph-large * $modifier;
    }
  }
  @if $element == h1 {
    font-size: $font-size-h1 * $modifier;
    @media screen and (min-width: $breakpoint-medium) {
      font-size: $font-size-h1-medium * $modifier;
    }
    @media screen and (min-width: $breakpoint-large) {
      font-size: $font-size-h1-large * $modifier;
    }
  }
  @if $element == h2 {
    font-size: $font-size-h2 * $modifier;
    @media screen and (min-width: $breakpoint-medium) {
      font-size: $font-size-h2-medium * $modifier;
    }
    @media screen and (min-width: $breakpoint-large) {
      font-size: $font-size-h2-large * $modifier;
    }
  }
  @if $element == h3 {
    font-size: $font-size-h3 * $modifier;
    @media screen and (min-width: $breakpoint-medium) {
      font-size: $font-size-h3-medium * $modifier;
    }
    @media screen and (min-width: $breakpoint-large) {
      font-size: $font-size-h3-large * $modifier;
    }
  }
  @if $element == h4 {
    font-size: $font-size-h4 * $modifier;
    @media screen and (min-width: $breakpoint-medium) {
      font-size: $font-size-h4-medium * $modifier;
    }
    @media screen and (min-width: $breakpoint-large) {
      font-size: $font-size-h4-large * $modifier;
    }
  }
}
